











































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Datepicker from "@/components/Datepicker.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import { PropType } from "vue";
import { IBranch } from "@/interfaces/IBranch";
import { IInstructor } from "@/interfaces/IInstructor";
import BaseMixin from "@/mixins/BaseMixin";
import { mixins } from "vue-class-component";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";

@Component({
  components: {
    Validation,
    SaveButton,
    AbortButton,
    Datepicker,
    FscSimpleCard,
  },
})
export default class ReportLearnFilter extends mixins(BaseMixin) {
  public name = "ReportLearnFilter";

  @Prop({ type: Array as PropType<Array<IBasicNamedDTO>>, default: () => [] })
  public licenseClassGroups!: Array<IBasicNamedDTO>;

  @Prop({ type: Array as PropType<Array<IBranch>>, default: () => [] })
  public branches!: Array<IBranch>;

  @Prop({ type: Boolean, default: () => true })
  public showHintText!: boolean;

  @Prop()
  public selectedRow!: any;

  public startDate = null;
  public endDate = null;
  public licenseClasses: any = null;
  public branchIds: any = null;

  private onAbort(): void {
    this.$emit("on-close");
  }

  public branchLabel(branch: IBranch): string {
    return `${branch.postalCode} - ${branch.location}`;
  }

  public onBranchIds() {
    if (this.branchIds) {
      let ids = this.branchIds.map((branch: any) => {
        return branch.id;
      });
      return ids;
    }
    return null;
  }

  public onLicenseClassGroupIds() {
    if (this.licenseClasses) {
      let ids = this.licenseClasses.map((licenseClass: any) => {
        return licenseClass.id;
      });
      return ids;
    }
    return null;
  }

  public onSubmit(): void {
    this.$v.$touch();
    if (this.$v.$invalid) return;
    const filter = {
      startDate: this.startDate,
      endDate: this.endDate,
      licenseClasses: this.onLicenseClassGroupIds(),
      branchIds: this.onBranchIds(),
    };
    const filterInfo = {
      startDate: this.startDate,
      endDate: this.endDate,
      licenseClassIds: this.licenseClasses,
      branchIds: this.branchIds,
    };
    this.$emit("on-submit", filter, filterInfo);
    this.$v.$reset();
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      startDate: { required },
      endDate: { required },
    };
  }

  @Watch("selectedRow", { immediate: true, deep: true })
  public onSelectedRowChange(row: any): void {
    if (row) {
      this.startDate = null;
      this.endDate = null;
      this.licenseClasses = null;
      this.branchIds = null;
    }
  }
}
