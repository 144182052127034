












































import { Component, Vue, Watch } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import ReportFinances from "@/views/Report/ReportFinances.vue";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import { namespace } from "vuex-class";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { IBranch } from "@/interfaces/IBranch";
import ReportLearnChart from "@/views/Report/ReportLearnChart.vue";
import ReportLearnFilter from "@/views/Report/ReportLearnFilter.vue";
import ReportQuestionAnsweredToday from "@/views/Report/ReportQuestionAnsweredToday.vue";
import ReportTrainingFilter from "@/views/Report/ReportTrainingFilter.vue";
import moment from "moment";
import eventBus from "@/plugins/EventBus";
import ReportRequestMixin from "@/mixins/Request/ReportRequestMixin";
import { mixins } from "vue-class-component";

const LicenseClassModule = namespace("license-class");
const BranchModule = namespace("branch");
const ReportModule = namespace("report");

@Component({
  components: {
    ReportLearnChart,
    FscSimpleCard,
    FscPageHeader,
    ReportFinances,
    ReportLearnFilter,
    ReportQuestionAnsweredToday,
    ReportTrainingFilter,
  },
})
export default class Learn extends mixins(ReportRequestMixin) {
  public name = "Learn";

  @LicenseClassModule.Action("groupsList/findAll")
  public findAllLicenseClassGroups!: (options: ICrudOptions) => Promise<void>;

  @LicenseClassModule.Getter("groupsList/getDataList")
  public licenseClassGroups!: Array<IBasicNamedDTO>;

  @BranchModule.Action("findAll")
  public findAllBranches!: (options: ICrudOptions) => Promise<void>;

  @BranchModule.Getter("getDataList")
  public branches!: Array<IBranch>;

  @ReportModule.Action("answeredQuestionsPerDrivingSchool/filter")
  public filterAnsweredQuestionsPerDrivingSchool!: (options: ICrudOptions) => Promise<void>;

  @ReportModule.Getter("answeredQuestionsPerDrivingSchool/getData")
  public answeredQuestionsPerDrivingSchool!: any;

  @ReportModule.Getter("answeredQuestionsPerDrivingSchool/getIsLoading")
  public answeredQuestionsPerDrivingSchoolLoading!: boolean;

  public filterData: any = null;

  public filterInfo: any = null;

  public selectedRow: null | IBasicNamedDTO = null;

  public answeredQuestions: any = null;
  public info: any = {};

  private reportLernen: any = [
    {
      id: 1,
      name: "drive.buzz Lernwelt",
    },
    // {
    //   id: 1,
    //   name: this.$t("report.average_pre_exams"),
    // },
    // {
    //   id: 2,
    //   name: this.$t("report.average_learn_time"),
    // },
    // {
    //   id: 3,
    //   name: this.$t("report.questions_total"),
    // },
    // {
    //   id: 4,
    //   name: this.$t("report.questions_today"),
    // },
  ];

  public mounted(): void {
    this.findAllLicenseClassGroups({
      resource: `license-classes/for-export?exportType=10`,
    });

    this.findAllBranches({
      resource: "/branches",
      params: { archived: false },
    });

    this.fetchDailyDataStartPeriod();
  }

  public onSelectedRow(row: IBasicNamedDTO): void {
    this.selectedRow = row;
    this.answeredQuestions = null;
    this.filterData = null;
  }

  public onCloseFilterForm(): void {
    this.selectedRow = null;
  }

  public get isNumberOfQuestionsAnsweredPerDay(): boolean {
    return Boolean(this.selectedRow && this.selectedRow.id === 1);
  }

  public extractValuesByPropFromObject(obj: any, propName: any, propName2?: any): string {
    if (!obj || !propName) return "";
    if (Array.isArray(obj)) {
      const value = obj.map((item: any) => item[propName] + " " + (item[propName2] || ""));
      if (value && value?.length > 0) {
        return value.join(", ");
      }
    } else if (Object.keys(obj) && Object.keys(obj).includes(propName)) {
      return obj[propName];
    }
    return "";
  }
  public extractStringFromFilters() {
    const { startDate, endDate, licenseClassIds, branchIds } = this.filterInfo;
    const licenseClasses = this.extractValuesByPropFromObject(licenseClassIds, "name");
    const branches = this.extractValuesByPropFromObject(branchIds, "location");
    this.info = { startDate, endDate, licenseClasses, branches };
    eventBus.$emit("selected-filter", this.info);
  }

  public async onSubmitFilterForm(filter: any, filterInfo: any): Promise<void> {
    this.filterData = filter;
    this.filterInfo = filterInfo;
    this.extractStringFromFilters();
    switch (this.selectedRow && this.selectedRow.id) {
      case 1:
        this.filterData.endDate = moment(filter.endDate).add(1, "days").format("YYYY-MM-DD");
        await this.filterAnsweredQuestionsPerDrivingSchool({
          resource: "reports/learning-export",
          filter: this.filterData,
        });
        break;
      default:
        console.log("DEFAULT");
    }
  }

  @Watch("answeredQuestionsPerDrivingSchoolLoading", { immediate: true })
  public onLoadingChange(loading: any) {
    if (loading) {
      this.$toasted.info("Bitte einen Augenblick Geduld. Dein Fahrschulcockpit sucht gerade die von dir angeforderten Daten zusammen!");
    } else {
      this.$toasted.clear();
    }
  }
}
